<template>
  <v-container  fluid style="margin-top:50px; margin-bottom: 67px;;" class="gray_bg_container">
      <v-container>
        <v-card style="margin-bottom:15px;background: rgba(255, 255, 255, 1);
box-shadow: 4px 4px 10px rgba(75, 60, 50, 0.2);
border-radius: 20px;" >
            <v-card-title>
        
           
          </v-card-title>

            <v-card-text class="brand_font"> 
      
              <center><font class="brand_in_header">WEST XL</font></center><br>
              Більший формат (25 сигарет в пачці) від надійного та визнаного міжнародного бренду.
              <br><br>
Легендарна тютюнова суміш Original Blend, що визнана у всьому світі, пропонує споживачеві м’який та насичений смак.
<br><br>
Вже понад 40 років West зберігає стандарти якості та виробляється за ліцензією «Reemtsma Cigarettenfabriken GmbH», Німеччина. West XL – одна з найдоступніших та найпопулярніших пропозицій у форматі «25 сигарет у пачці» на ринку України.








<v-row class="" >
  <v-col cols="6" class=""
    >
      <center> <img src="img/pack_brands/2024/n_w_xl_red.png" style="max-height:154px;width:auto;"></center>
    </v-col>
    <v-col cols="6" class=""
    >
     <center> <img src="img/pack_brands/2024/n_w_xl_blue.png" style="max-height:154px;width:auto;"></center>
    </v-col>
    
</v-row>

<v-row class=""  style="margin-top:unset">
  <v-col cols="6" class="pa-0" style="text-align:center; font-size: 20px;"
    >
    West Red XL<br><span style="font-size: 18px;">смол/нікотину<br>10/0.7</span>
    </v-col>
    <v-col cols="6" class="pa-0" style="text-align:center; font-size: 20px;"
    >
    West Blue XL<br><span style="font-size: 18px;">смол/нікотину<br>6/0.5</span>
    </v-col>
</v-row>


<v-row class="" >
  <v-col cols="6" class=""
    >
    <center>  <img src="img/pack_brands/2024/n_w_xl_silver.png" style="max-height:154px;width:auto;"></center>
    </v-col>
    <v-col cols="6" class=""
    >
    </v-col>
</v-row>



<v-row class=""   style="margin-top:unset">
      <v-col cols="6" class="pa-0" style="text-align:center; font-size: 20px;"
        >
        West Silver XL<br><span style="font-size: 18px;">смол/нікотину<br>4/0.4</span>
        </v-col>
        <v-col cols="6" class="pa-0" style="text-align:center"
        >
        </v-col>
    </v-row>

</v-card-text>
</v-card>
    

<p  style="margin-bottom:10px;text-align: center;" class="brand_preferences">Основні переваги:</p>
          <table style="width:100%" class="spacer">
            <tr >
              <td class="brand_preference_ico"><img style="width:100%;" src="img/bico/5star.png"></td>
              <td style="text-align:left;" class="c-ma-l brand_preferences_text">Європейська якість</td>
            </tr>
            <tr >
              <td class="brand_preference_ico"><img style="width:100%;" src="img/bico/pack40.png"></td>
              <td style="text-align:left;" class="c-ma-l brand_preferences_text">Більший формат в сучасному оновленому дизайні</td>
            </tr>
            <tr >
              <td class="brand_preference_ico"><img style="width:100%;" src="img/bico/cig.png"></td>
              <td style="text-align:left;" class="c-ma-l brand_preferences_text">Найпопулярніші варіанти міцності</td>
            </tr>
           
            
          </table>
     
      </v-container>

 
     
    </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
import btnsqr from "../components/btnsqr.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,btnsqr
  },
  data: () => ({
    faq:false,
    write:false,
    write_tp:false,
    contact:false,
    
    
        accordion: false,
    popout: false,
    inset: false,
    multiple: false,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    plnm:false,
    req_tp:'',
    req_tp_tp:'',
        apiurl:API_LOCATION,
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
      title() {
        return this.$t('title')
      },
      faqs(){
        
        return[
          
          {'header':this.$t('q1'),'text':this.$t('a1')},
          {'header':this.$t('q2'),'text':this.$t('a2')},
          {'header':this.$t('q3'),'text':this.$t('a3')},
          {'header':this.$t('q4'),'text':this.$t('a4')},
          {'header':this.$t('q5'),'text':this.$t('a5')},
          {'header':this.$t('q6'),'text':this.$t('a6')},
          {'header':this.$t('q7'),'text':this.$t('a7')},
          {'header':this.$t('q8'),'text':this.$t('a8')},
          {'header':this.$t('q9'),'text':this.$t('a9')},
          {'header':this.$t('q10'),'text':this.$t('a10')}
        ]},
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
     
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
  },
  methods:{
        save_request: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create', 'question':this.req_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write=false;
            this.req_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

        save_request_tp: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create_tp', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

    }
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Про бренди",
      "btn1":"Davidoff",
      "btn2":"West",
      "btn3":"Parker & Simpson",
      "btn4":"Jade",
      "close":"Закрити",
      "h_write":"Надіслати повідомлення до служби підтримки",
      "h_contact":"Надіслати повідомлення торговому представнику",
      "h_faq":"Відповіді на часті питання",
      "q1":"Чи можу я змінити номер телефону для участі у програмі?",
      "a1":"Так, це можливо. Зверніться, будь ласка, до Вашого Торгового Представника.",

      "q2":"Яку максимальну кількість балів я можу отримати за місяць?",
      "a2":"Максимальна кількість балів, яку можна отримати, залежить від типу Вашого контракту: <br>К300 – 300 балів, <br>К400 – 400 балів,<br>К500 – 500 балів. <br>Виконання екстра завдань дає можливість заробити додаткові бали в  доповнення до стандартної кількості балів контракту.",
      
      "q3":"Я зробив(-ла) помилку при виборі асортименту для участі. Чи можу я його змінити?",
      "a3":"Зміна асортименту для участі доступна раз на один квартал. Для уточнення інформації зверніться, будь ласка, до Вашого Торгового Представника.",

      "q4":"Чи зберігаються на наступний місяць мої накопичені бали?",
      "a4":"Так, зберігаються.",

      "q5":"Мені не нарахували бали за завдання «Фото вітрини» та «Мій товарний запас», у чому причина?",
      "a5":"Усі фото перевіряються вручну модератором, тому модерація може займати декілька днів. Точну інформацію Ви можете дізнатись у свого Торгового Представника.",

      "q6":"Які критерії враховуються при перевірці завдання «Фото вітрини»?",
      "a6":"Критеріями перевірки є  наявність усіх SKU, (відповідно до обраного асортименту) та правильність їх викладки.",

      "q7":"Якщо я беру участь в програмі як мережева торгова точка, скільки SKU має бути на фото вітрини?",
      "a7":"На фото вітрини має бути 32 SKU із актуального асортименту програми (актуальний асортимент уточнюйте у Вашого Торгового Представника).",

      "q8":"Які критерії враховуються при перевірці завдання «Мій товарний запас»?",
      "a8":"Критерієм перевірки є наявність на фото блоку кожного SKU із списку, наведеного у завданні, з нанесеним унікальним кодом.",

      "q9":"Я ввів(-ла) неправильний номер картки Fishka, чи можна його змінити?",
      "a9":"Так, змінити номер карти Fishka можна. Для цього достатньо звернутися у службу тех. підтримки iTeam.",

      "q10":"Як довго чекати на замовлені емоції?",
      "a10":"Поповнення мобільного рахунку та нарахування балів Fishka відбувається одразу (15-20 хвилин) автоматично, замовлені електронні сертифікати «Сільпо», «Єва» та «Розетка» з'являються у застосунку одразу."


      

  }
}
</i18n>